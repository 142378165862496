import { When } from "react-if";

import { UILineItem } from "../../lib/invoiceHelper";

import { InvoiceLineItem } from "./InvoiceLineItem";

interface InvoiceLineItemsProps {
  lineItems: UILineItem[];
  includeAccountCode?: boolean;
}

export function InvoiceLineItems({ lineItems, includeAccountCode }: InvoiceLineItemsProps) {
  return (
    <>
      <colgroup>
        <col className={ `w-full` } />
        <col />
        <When condition={ includeAccountCode }>
          <col />
        </When>
        <col />
        <col />
      </colgroup>
      <thead className={ `border-b border-gray-200 text-gray-900` }>
        <tr>
          <th scope={ `col` }
            className={ `px-0 py-3 font-semibold` }>
            { `Description` }
          </th>
          <th scope={ `col` }
            className={ `hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell` }>
            { `Quantity` }
          </th>
          <When condition={ includeAccountCode }>
            <th scope={ `col` }
              className={ `hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell` }>
              { `Account Code` }
            </th>
          </When>
          <th scope={ `col` }
            className={ `hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell` }>
            { `Amount` }
          </th>
          <th scope={ `col` }
            className={ `py-3 pl-8 pr-0 text-right font-semibold` }>
            { `Total` }
          </th>
        </tr>
      </thead>
      <tbody>
        { lineItems.map((item, index) => (
          <InvoiceLineItem
            key={ `lineitem-${index}-${item.description}` }
            description={ item.description }
            quantity={ item.quantity }
            unitAmount={ item.amount }
            taxAmount={ item.taxAmount }
            totalAmount={ item.total }
            accountCode={ item.accountCode }
            includeAccountCode={ includeAccountCode }
          />
        )) }
      </tbody>
    </>
  );
}
