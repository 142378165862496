import React from 'react';

import { classNames } from '../lib/classNames';

interface IFormLabelProps {
  name?: string
  children: React.ReactNode
  className?: string
}

export function FormLabel({ name, children, className }: IFormLabelProps) {
  return (
    <label
      htmlFor={ name }
      className={
        classNames(
          `block text-sm font-medium text-gray-500`,
          className,
        )
      }
    >
      { children }
    </label>
  );
}

export const ForwardRefFormLabel = React.forwardRef<HTMLLabelElement, IFormLabelProps>(({ name, children, className }: IFormLabelProps, ref) => {
  return (
    <label
      htmlFor={ name }
      className={
        classNames(
          `block text-sm font-medium text-gray-500`,
          className,
        )
      }
      ref={ ref }
    >
      { children }
    </label>
  );
},
);

ForwardRefFormLabel.displayName = `FormLabel`;
