import { useEffect, useState } from 'react';
import { QuoteStatus, accountingSystemSpecificTerm, displayPhoneNumber } from 'shared';
import { When } from 'react-if';

import { LoadingFallback } from '../LoadingFallback';
import { ErrorMessage } from '../Error';
import { mixpanelService } from '../../../common/lib/mixpanel';
import { usePortalQuote } from '../../hooks/usePortalQuote';
import { Card } from '../../../common/Atoms/Card';
import { Heading } from '../../../common/Atoms/Typography/Heading';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { InvoiceSenderDetail } from '../../../common/Components/Invoice/InvoiceSenderDetail';
import { LoadingOverlay } from '../../../main/components/Molecules/LoadingOverlay';
import { DatePopover } from '../../../common/Components/DatePopover';
import { Badge } from '../../../common/Atoms/Badge';
import { classNames } from '../../../common/lib/classNames';

import { QuoteActions } from './QuoteActions';

export function Quote() {
  const { data: response, isLoading, error, isFetching } = usePortalQuote();
  const [pageViewed, setPageViewed] = useState(false);

  useEffect(() => {
    if (!pageViewed && response && !isLoading && !error) {
      mixpanelService.portalPageLoad(`quote`);
      setPageViewed(true);
    }
  }, [pageViewed, response, isLoading, error]);

  if (error) {
    return <ErrorMessage error={ error } />;
  }

  if (!response && isLoading) {
    return <LoadingFallback />;
  }

  if (!response) {
    return null;
  }

  const quoteTerm = accountingSystemSpecificTerm({ type: response?.quote?.accountingSystemType }, `Quote`);

  const hasDiscounts = response?.quote?.linesContainDiscounts;

  const quoteHeader = (
    <div className={ `w-full justify-center flex` }>
      <div className={ `flex flex-col items-center mb-6` }>
        <Heading
          size={ `3xl` }
          className={ `` }
        >
          { quoteTerm }
          { ` ` }
          { response?.quote?.number }
        </Heading>
        { /* Status */ }
        <When condition={ response?.quote?.status === QuoteStatus.ACCEPTED || response?.quote?.status === QuoteStatus.CONVERTED }>
          <Badge
            color={ `green` }
            size={ `xl` }
          >
            { response?.quote?.status }
          </Badge>
        </When>

        <When condition={ response?.quote?.status === QuoteStatus.DECLINED }>
          <Badge
            color={ `red` }
            size={ `xl` }
          >
            { response?.quote?.status }
          </Badge>
        </When>
        { /* Expiry Date */ }
        <When condition={ response?.quote?.expiryDateString && response?.quote?.status === QuoteStatus.SENT }>
          <div className={ classNames(
            `flex space-x-2 mt-2`,
            response?.quote?.hasExpired && `text-red-600`,
          ) }>
            <Heading>
              { response?.quote?.hasExpired ? `Expired:` : `Expires:` }
            </Heading>

            <DatePopover
              date={ response.quote.expiryDateString }
              labelFormat={ `dd LLL yyyy` }
              organisationTimezone={ response?.organisationTimezone }
              time={ `end` }
            />
          </div>
        </When>
      </div>
    </div>
  );

  const quoteDocument = (
    <Card>
      { /* To & From */ }
      <div className={ `mb-6 flex justify-between` }>
        <InvoiceSenderDetail
          senderDetail={ response?.senderDetail }
        />
        { /* Contact Details */ }

        <div>
          <dt className={ `font-bold text-gray-900` }>{ `To` }</dt>
          <dd className={ `mt-2 text-gray-500` }>
            <div className={ `flex mb-1` }>
              <Heading
                variant={ `secondary` }
              >
                { response?.contact?.name }
              </Heading>

            </div>
            <div className={ `flex flex-col` }>
              <Paragraph variant={ `help` }>
                { displayPhoneNumber(response?.contact.primaryContactPerson.mobileCountryCode, response?.contact.primaryContactPerson.mobileNumber) }
              </Paragraph>
              <Paragraph variant={ `help` }>
                { displayPhoneNumber(response?.contact.primaryContactPerson.defaultCountryCode, response?.contact.primaryContactPerson.defaultNumber) }
              </Paragraph>
            </div>
          </dd>
        </div>
      </div>

      { /* Reference (if any) */ }
      <When condition={ response?.quote?.referenceText }>
        <div className={ `flex mb-4 items-center` }>
          <Heading className={ `mr-2` }>
            { `Reference:` }
          </Heading>
          <Paragraph>
            { response?.quote?.referenceText }
          </Paragraph>
        </div>
      </When>

      { /* Title & Summary (if any) */ }
      <When condition={ response?.quote?.titleText || response?.quote?.summaryText }>
        <div className={ `mb-4` }>
          <When condition={ response?.quote?.titleText }>
            <Heading>
              { response?.quote?.titleText }
            </Heading>
          </When>

          <When condition={ response?.quote?.summaryText }>
            <Paragraph
              variant={ `secondary` }
              className={ `whitespace-pre-wrap` }
            >
              { response?.quote?.summaryText }
            </Paragraph>
          </When>
        </div>
      </When>

      <table className={ `w-full` }>
        <thead className={ `border-b border-gray-200 text-gray-900` }>
          <tr>
            <th scope={ `col` }
              className={ `px-0 py-3 font-semibold` }>
              { `Description` }
            </th>
            <th scope={ `col` }
              className={ `hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell` }>
              { `Quantity` }
            </th>
            <When condition={ hasDiscounts }>
              <th scope={ `col` }
                className={ `hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell` }>
                { `Discount` }
              </th>
            </When>
            <th scope={ `col` }
              className={ `hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell` }>
              { `Amount` }
            </th>
            <th scope={ `col` }
              className={ `py-3 pl-8 pr-0 text-right font-semibold` }>
              { `Total` }
            </th>
          </tr>
        </thead>
        <tbody>
          {
            response?.quote?.lineItems?.map((lineItem, index) => (
              <tr
                className={ `border-b border-gray-100` }
                key={ `${index}-quote-line-item` }
              >
                <td className={ `max-w-0 px-0 py-5 align-top` }>
                  <div className={ `truncate text-gray-500 whitespace-break-spaces` }>{ lineItem.description }</div>
                </td>
                <td className={ `hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell` }>
                  { lineItem.quantity }
                </td>
                <When condition={ hasDiscounts }>
                  <td className={ `hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell` }>
                    { lineItem.discountAmountFormatted }
                  </td>
                </When>
                <td className={ `hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell` }>
                  { lineItem.itemAmountFormatted }
                </td>
                <td className={ `py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700` }>
                  { lineItem.amountTotalFormatted }
                </td>
              </tr>
            ))
          }

          { /* Totals */ }
          <When condition={ response?.quote?.subTotalAmountFormatted }>
            <tr className={ `` }>
              <td
                colSpan={ hasDiscounts ? 5 : 4 }
                className={ `text-right space-x-2 pt-4` }
              >
                <Paragraph
                  as={ `span` }
                  variant={ `primary` }
                  size={ `md` }
                  className={ `font-bold` }
                >
                  { `Subtotal` }
                </Paragraph>
                <Paragraph
                  as={ `span` }
                  size={ `md` }
                >
                  { response?.quote?.subTotalAmountFormatted }
                </Paragraph>
              </td>
            </tr>
          </When>

          <When condition={ response?.quote?.totalDiscountAmountFormatted }>
            <tr className={ `` }>
              <td
                colSpan={ hasDiscounts ? 5 : 4 }
                className={ `text-right space-x-2 pt-4` }
              >
                <Paragraph
                  as={ `span` }
                  variant={ `primary` }
                  size={ `md` }
                  className={ `font-bold` }
                >
                  { `Discounts` }
                </Paragraph>
                <Paragraph
                  as={ `span` }
                  size={ `md` }
                >
                  { response?.quote?.totalDiscountAmountFormatted }
                </Paragraph>
              </td>
            </tr>
          </When>

          <When condition={ response?.quote?.totalTaxAmountFormatted }>
            <tr className={ `` }>
              <td
                colSpan={ hasDiscounts ? 5 : 4 }
                className={ `text-right space-x-2 pt-4` }
              >
                <Paragraph
                  as={ `span` }
                  variant={ `primary` }
                  size={ `md` }
                  className={ `font-bold` }
                >
                  { `Total Tax` }
                </Paragraph>
                <Paragraph
                  as={ `span` }
                  size={ `md` }
                >
                  { response?.quote?.totalTaxAmountFormatted }
                </Paragraph>
              </td>
            </tr>
          </When>

          <tr className={ `border-b border-gray-100` }>
            <td
              colSpan={ hasDiscounts ? 5 : 4 }
              className={ `text-right space-x-2 py-4` }
            >
              <Paragraph
                as={ `span` }
                variant={ `primary` }
                size={ `md` }
                className={ `font-bold` }
              >
                { `Total` }
              </Paragraph>
              <Paragraph
                as={ `span` }
                size={ `md` }
              >
                { response?.quote?.amountTotalFormatted }
              </Paragraph>
            </td>
          </tr>
        </tbody>
      </table>

      { /* Terms */ }
      <When condition={ response?.quote?.termsText }>
        <div className={ `mt-6` }>
          <Heading
            variant={ `secondary` }
          >
            { `Terms` }
          </Heading>

          <Paragraph
            variant={ `secondary` }
            className={ `whitespace-pre-wrap` }
          >
            { response?.quote?.termsText }
          </Paragraph>
        </div>
      </When>

      <When condition={ response?.quote?.customerDataPONumber }>
        <div className={ `flex space-x-2` }>
          <Paragraph>
            { `Purchase Order Number: ` }
          </Paragraph>

          <Paragraph
            variant={ `help` }
          >
            { response?.quote?.customerDataPONumber }
          </Paragraph>
        </div>
      </When>

      <When condition={ response?.quote?.customerDataPOFilename }>
        <div className={ `flex space-x-2` }>
          <Paragraph>
            { `Uploaded Purchase Order: ` }
          </Paragraph>

          <Paragraph
            variant={ `help` }
          >
            { response?.quote?.customerDataPOFilename }
          </Paragraph>
        </div>
      </When>

    </Card>
  );

  return (
    <LoadingOverlay
      loading={ isFetching || isLoading }
    >
      <div className={ `max-w-full sm:max-w-3xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto` }>
        { quoteHeader }

        <QuoteActions />

        { quoteDocument }
      </div>
    </LoadingOverlay>
  );
}
