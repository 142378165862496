import { accountingSystemSpecificTerm, IPortalFileUploadResponse, IPortalQuote } from "shared";
import { ChangeEvent, useRef, useState } from "react";
import { When } from "react-if";

import { Modal } from "../../../common/Atoms/Modal";
import { Button } from "../../../common/Atoms/Button";
import { InputTextAddon } from "../../../common/Atoms/InputTextAddon";
import { CardContent } from "../../../common/Atoms/CardContent";
import { useUploadPOFileMutation } from "../../services/api/quote";
import { useSession } from "../../hooks/useSession";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";

interface QuoteAcceptModalProps {
  open: boolean;
  onClose: () => void;
  quote?: IPortalQuote;
  onAccept: (poNumber?: string, poUploadUrl?: string, poUploadName?: string) => void;
  allowedQuotePOField?: boolean | null;
  allowedQuotePOUpload?: boolean | null;
  acceptLoading?: boolean;
}

export function QuoteAcceptModal({
  open,
  onClose,
  quote,
  onAccept,
  allowedQuotePOField,
  allowedQuotePOUpload,
  acceptLoading,
}: QuoteAcceptModalProps) {
  const [poNumberText, setPoNumberText] = useState(``);
  const [poUploadResponse, setPoUploadResponse] = useState<IPortalFileUploadResponse | null>(null);
  const [uploadPOFile, { isLoading: isUploading }] = useUploadPOFileMutation();
  const fileUploderRef = useRef(null);
  const session = useSession();

  function onUploadClick() {
    // Programmatically click the file uploader
    if (fileUploderRef.current?.click) {
      fileUploderRef?.current?.click();
    }
  }

  async function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    if (!session?.id || !quote) {
      console.error(`Session or quote not found - unable to upload file`);

      return;
    }

    const fileUploaded = event.target.files[0];

    const formData = new FormData();

    formData.append(`file`, fileUploaded);

    const response = await uploadPOFile({
      code: quote.hash,
      sessionId: session.id,
      formData,
    });

    if (response.data) {
      setPoUploadResponse({
        url: response.data.url,
        name: response.data.name,
      });

      console.log(`File uploaded successfully`, response.data);
    }

    fileUploderRef.current.value = null;
  }

  if (!quote) {
    return null;
  }
  const quoteTerm = accountingSystemSpecificTerm({ type: quote?.accountingSystemType }, `Quote`);

  return (
    <Modal
      open={ open }
      setOpen={ onClose }
      title={ `Accept ${quoteTerm}` }
    >
      <CardContent
        className={ `space-y-4` }
      >
        { /* Purchase order number field */ }
        <When condition={ allowedQuotePOField }>
          <InputTextAddon
            label={ `Purchase Order Number` }
            value={ poNumberText }
            onChange={ e => setPoNumberText(e.value?.toString()) }
          />
        </When>

        { /* Purchase order doc upload */ }
        <When condition={ allowedQuotePOUpload }>
          <input
            type={ `file` }
            ref={ fileUploderRef }
            onChange={ handleFileUpload }
            className={ `hidden` }
          />

          <div>
            <Button
              onClick={ onUploadClick }
              color={ `gray` }
              loading={ isUploading }
              disabled={ isUploading }
            >
              { `Upload Purchase Order` }
            </Button>
            <When condition={ poUploadResponse?.name }>
              <div>
                <Paragraph
                  variant={ `help` }
                >
                  <Paragraph
                    as={ `span` }
                    variant={ `note` }
                  >
                    { `File uploaded: ` }
                  </Paragraph>
                  { poUploadResponse?.name || `` }

                </Paragraph>
              </div>
            </When>
          </div>
        </When>
        <div className={ `flex justify-end mt-4` }>
          <Button
            onClick={ () => onAccept(poNumberText, poUploadResponse?.url, poUploadResponse?.name) }
            color={ `green` }
            loading={ acceptLoading }
            disabled={ acceptLoading || isUploading }
          >
            { `Accept ${quoteTerm}` }
          </Button>
        </div>
      </CardContent>
    </Modal>
  );
}
