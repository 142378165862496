import { accountingSystemSpecificTerm, QuoteStatus } from "shared";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

import { Button } from "../../../common/Atoms/Button";
import { Card } from "../../../common/Atoms/Card";
import { useDownload } from "../../../common/hooks/useDownload";
import { env } from "../../../common/lib/env";
import { usePortalQuote } from "../../hooks/usePortalQuote";
import { useAcceptQuoteMutation, useDeclineQuoteMutation } from "../../services/api/quote";
import { useSession } from "../../hooks/useSession";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";

import { QuoteAcceptModal } from "./QuoteAcceptModal";

export function QuoteActions() {
  const {
    data: response,
    refetch,
  } = usePortalQuote();
  const session = useSession();

  const [accept, { isLoading: acceptLoading }] = useAcceptQuoteMutation();
  const [decline, { isLoading: declineLoading }] = useDeclineQuoteMutation();

  const {
    download: downloadPdf,
    downloading: downloadingPdf,
  } = useDownload();

  const [acceptQuoteModalOpen, setAcceptQuoteModalOpen] = useState(false);

  const quoteTerm = accountingSystemSpecificTerm({ type: response?.quote?.accountingSystemType }, `Quote`);

  function onDownloadPdf() {
    if (response?.quote) {
      const url = `${env.basePortalApiUrl}quote/code/${response.quote.hash}/download.pdf`;
      const filename = `quote_${response.contact.name}_${response.quote.number || ``}.pdf`;
      downloadPdf(url, filename);
    }
  }

  async function onAccept() {
    if (response?.quote && session?.id) {
      if (response.allowedQuotePOField || response.allowedQuotePOUpload) {
        // Instead show the modal
        setAcceptQuoteModalOpen(true);

        return;
      }

      await accept({ code: response.quote.hash, sessionId: session.id });

      // For some reason, tags not working
      refetch();
    }
  }

  async function onDecline() {
    if (response?.quote && session?.id) {
      await decline({ code: response.quote.hash, sessionId: session.id });

      // For some reason, tags not working
      refetch();
    }
  }

  async function onAcceptModal(poNumber?: string, poUploadUrl?: string, poUploadName?: string) {
    if (response?.quote && session?.id) {
      await accept({ code: response.quote.hash, sessionId: session.id, poNumber, poUploadUrl, poUploadName });

      // For some reason, tags not working
      refetch();

      setAcceptQuoteModalOpen(false);
    }
  }

  return (
    <>
      <Card className={ `flex justify-between mb-4` }>
        <div className={ `` }>
          <Paragraph
            variant={ `link` }
            className={ `mb-2` }
          >
            <When condition={ response?.contactLink }>
              <Link to={ response?.contactLink + `/quotes` }>
                <ArrowLeftOnRectangleIcon className={ `inline-block mr-1 h-4 w-4` } />
                { `View other ${quoteTerm?.toLowerCase()}s` }
              </Link>
            </When>
          </Paragraph>
          <div className={ `space-x-2` }>
            <Button
              size={ `lg` }
              color={ `green` }
              disabled={ response?.quote?.status !== QuoteStatus.SENT || acceptLoading || declineLoading || response?.quote?.hasExpired }
              loading={ acceptLoading }
              onClick={ onAccept }
            >
              { `Accept` }
            </Button>
            <Button
              color={ `red` }
              size={ `lg` }
              disabled={ response?.quote?.status !== QuoteStatus.SENT || declineLoading || acceptLoading || response?.quote?.hasExpired }
              loading={ declineLoading }
              onClick={ onDecline }
            >
              { `Decline` }
            </Button>
          </div>
        </div>

        <div>
          <Button
            size={ `lg` }
            color={ `gray` }
            loading={ downloadingPdf }
            onClick={ onDownloadPdf }
            disabled={ downloadingPdf }
          >
            { `Download PDF` }
          </Button>
        </div>
      </Card>

      <QuoteAcceptModal
        open={ acceptQuoteModalOpen }
        onClose={ () => setAcceptQuoteModalOpen(false) }
        onAccept={ onAcceptModal }
        quote={ response?.quote }
        allowedQuotePOField={ response?.allowedQuotePOField }
        allowedQuotePOUpload={ response?.allowedQuotePOUpload }
        acceptLoading={ acceptLoading }
      />
    </>
  );
}
