
import LogRocket from 'logrocket';
import { Middleware } from '@reduxjs/toolkit';

import { env } from '../lib/env.js';

import { rtkQueryActionLogger } from './rtkQueryActionLogger.js';

export function createMiddleware(getDefaultMiddleware, extendedApiMiddleware: Middleware) {
  const middleware = getDefaultMiddleware();

  // Add the API Slice
  middleware.push(extendedApiMiddleware);

  // Add error listener for toasts
  middleware.push(rtkQueryActionLogger);

  if (env.environment === `production`) {
    middleware.push(LogRocket.reduxMiddleware());
  }

  return middleware;
}
