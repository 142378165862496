import { useMemo } from "react";
import { currencyFormatter, toDecimal } from "shared";

import { InvoiceDetails, parseLineItems } from "../lib/invoiceHelper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useInvoiceDetails(invoice: any) {
  const formatter = currencyFormatter(invoice?.xero_currency);

  const invoiceDetails = useMemo(() => {
    if (!invoice) return null;

    const subTotal = toDecimal(invoice.subtotal_cents);
    const total = toDecimal(invoice.xero_amount_total);
    const tax = toDecimal(invoice.total_tax_cents);
    const discounts = toDecimal(invoice.total_discount_cents);
    const paidAmount = toDecimal(invoice.total_paid_cents);
    const dueAmount = toDecimal(invoice.xero_amount_due);

    const details: InvoiceDetails = {
      number: invoice.xero_number,
      reference: invoice.xero_reference,
      status: invoice.xero_status,
      contact: invoice.contact,
      dueDateString: invoice.due_date_string,
      issueDateString: invoice.issue_date_string,
      organisationTimezone: invoice.organisationTimezone,
      lineItems: parseLineItems(invoice, formatter),
      subTotal: formatter(subTotal),
      total: formatter(total),
      tax: formatter(tax),
      discounts: formatter(discounts),
      paidAmount: formatter(paidAmount),
      dueAmount: formatter(dueAmount),
      externalLink: invoice.externalLink,
      frozen: invoice.skip,
      deleted: invoice.deleted,
      createdByUs: invoice.created_by_us,
      creditNotes: invoice.credit_notes,
      groupTitle: invoice.contact?.group?.title, // NOT USED IN PORTAL
      sent_to_customer: invoice.sent_to_customer,
      currency: invoice.xero_currency,
      hash: invoice.hash,
      expectedPaymentDate: invoice.expected_payment_date_str || undefined,
    };

    return details;
  }, [invoice]);

  return invoiceDetails;
}
