import React, { forwardRef } from 'react';

import { classNames }  from '../lib/classNames';

export interface ICardProps {
  children: React.ReactNode
  className?: string
  id?: string
}

export function Card({ children, className, id }: ICardProps) {
  return (
    <div
      id={ id }
      className={
        classNames(
          `rounded-lg bg-white shadow px-4 py-5 sm:p-5`,
          className,
        )
      }
    >
      { children }
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ForwardRefCard = forwardRef<any, any>(({ children, className, id }, ref) => {
  return (
    <div
      id={ id }
      className={
        classNames(
          `rounded-lg bg-white shadow px-4 py-5 sm:p-5`,
          className,
        )
      }
      ref={ ref }
    >
      { children }
    </div>
  );
} );

ForwardRefCard.displayName = `Card`;

