import { IPortalInvoiceResponse } from "shared";
import { useEffect, useMemo, useState } from "react";
import request from "axios";
import { toast } from "react-toastify";
import { When } from "react-if";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mui/material";

import { Card } from "../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { InvoiceDocumentCommon } from "../../../common/Components/Invoice/InvoiceDocument";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { env } from "../../../common/lib/env";
import { mixpanelService } from "../../../common/lib/mixpanel";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { CardContent } from "../../../common/Atoms/CardContent";
import { Button } from "../../../common/Atoms/Button";
import { Notification } from "../../../common/Atoms/Notification";
import { StripeCheckout } from "../Stripe/StripeCheckout";
import { LoadingOverlay } from "../../../main/components/Molecules/LoadingOverlay";
import { classNames } from "../../../common/lib/classNames";
import { DatePopover } from "../../../common/Components/DatePopover";
import { SmallSpinner } from "../../../common/Atoms/SmallSpinner";

interface InvoicePageProps {
  invoiceData: IPortalInvoiceResponse;
  isFetching?: boolean;
}

export function InvoicePage({ invoiceData, isFetching }: InvoicePageProps) {
  const [downloading, setDownloading] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [checkoutStatus, setCheckoutStatus] = useState(null);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const stripeCurrentSessionId = useMemo(() => {
    return urlParams.get(`session_id`);
  }, [urlParams]);

  const shouldShowPayButton = (invoiceData.stripeEnabled && invoiceData.stripeConnectAccount && invoiceData.invoice.xero_status === `AUTHORISED`);

  useEffect(() => {
    if (stripeCurrentSessionId && !checkoutStatus && shouldShowPayButton) {
      fetch(`${env.basePortalApiUrl}stripe-checkout-status?sessionId=${stripeCurrentSessionId}&accountId=${invoiceData.stripeConnectAccount}`)
        .then(res => res.json())
        .then(data => {
          setCheckoutStatus(data.status);

          // If successful, refresh the invoice data
        });
    }
  }, [stripeCurrentSessionId, checkoutStatus, shouldShowPayButton]);

  const title = (
    <div className={ `flex items-center` }>
      <Heading
        as={ `span` }
        size={ `lg` }
      >
        { `Invoice: ${ invoiceData.invoice.xero_number }` }
      </Heading>

      <When condition={ isFetching }>
        <SmallSpinner
          colored
          className={ `ml-2` }
        />
      </When>
    </div>
  );

  const pdfLink = `${env.basePortalApiUrl}invoices/code/${invoiceData.invoice.hash}/download.pdf`;

  async function onDownload() {
    setDownloading(true);

    try {
      const filename = `${invoiceData.senderDetail.customerFacingCompanyName}_${invoiceData.invoice.xero_number}.pdf`;

      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: pdfLink,
        params: {
          filename,
          mode: `download`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));

      const link = document.createElement(`a`);
      link.href = url;
      link.target = `_blank`;

      link.setAttribute(`download`, filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not download invoice`}`);
    }

    setDownloading(false);
  }

  function onPrint() {
    window.open(pdfLink + `?mode=print`, `_blank`);
  }

  function onOpenExternal() {
    // TODO when other systems have online invoices, for now, only Xero
    mixpanelService.portalInvoiceXeroClick();

    window.open(invoiceData.externalOnlineLink, `_blank`);
  }

  const buttons = useMemo(() => {
    return [
      {
        buttonText: `Save as PDF`,
        onClick: onDownload,
        loading: downloading,
      },
      {
        buttonText: `Print`,
        onClick: onPrint,
      },
    ];
  }, [downloading ]);

  const secondaryButtons = useMemo(() => {
    const text = (shouldShowPayButton || invoiceData.paymentLink) ? `View` : `View & Pay`;
    if (invoiceData.externalOnlineLink) {
      return [{
        buttonText: `${text} in ${invoiceData.language?.accountingSystemName}`,
        onClick: onOpenExternal,
      }];
    }

    return [];
  }, [invoiceData.externalOnlineLink, shouldShowPayButton, invoiceData.paymentLink, invoiceData.language?.accountingSystemName]);

  const successPaymentMessage = checkoutStatus === `complete` ? (
    <Notification
      type={ `successGreen` }
      className={ `mt-4` }
    >
      <div className={ `flex` }>
        <Paragraph
          className={ `text-green-600` }
        >
          { `Payment successful!` }
        </Paragraph>

        { /*  Work around for race condition */ }
        <a
          href={ window.location.href }
          onClick={ () => window.location.reload() }
        >
          <Paragraph
            variant={ `help` }
            className={ `ml-2 underline cursor-pointer text-green-600` }
          >
            { `Reload` }

          </Paragraph>
        </a>
      </div>
    </Notification>
  ) : null;

  let payButton: null | React.ReactNode = null;

  // Create for Pinch if exists
  if (invoiceData.paymentLink) {
    payButton = (
      <a
        href={ invoiceData.paymentLink }
        target={ `_blank` }
        rel={ `noreferrer noopener` }
      >
        <Button
          size={ `2xl` }
          color={ `green` }
        >
          { `Pay now` }
        </Button>
      </a>
    );
  }

  // WHen Stripe, override with stripe button
  if (shouldShowPayButton) {
    payButton = (
      <Button
        size={ `2xl` }
        color={ `green` }
        onClick={ () => setPaymentModalOpen(true) }
      >
        { `Pay with Card` }
      </Button>
    );
  }

  const toolbar = (invoiceData.contactLink || !!payButton) ? (
    <Card className={ `flex justify-between items-center` }>
      <Paragraph
        variant={ `link` }
      >
        <When condition={ invoiceData.contactLink }>
          <Link to={ invoiceData.contactLink }>
            <Button>
              <ArrowLeftOnRectangleIcon className={ `inline-block mr-1 h-4 w-4` } />
              { `View other invoices` }
            </Button>
          </Link>
        </When>
      </Paragraph>
      { payButton }
    </Card>
  ) : null;

  return (
    <LoadingOverlay
      loading={ false }
    >
      <div className={ `max-w-full sm:max-w-3xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto` }>
        { toolbar }
        { successPaymentMessage }
        { /* Link to source invoice if applicable */ }
        <When condition={ !!invoiceData.lateFeeFor }>
          { () => <Notification
            type={ `warning` }
            className={ `mt-4` }
          >
            { `This invoice was issued as a late fee for ` }
            <Link to={ `/i/${invoiceData.lateFeeFor.hash}` }>
              <Paragraph
                variant={ `link` }
                className={ `inline-block` }
              >
                { invoiceData.lateFeeFor.invoiceNumber }
                <ArrowTopRightOnSquareIcon className={ `inline-block ml-1 h-4 w-4` } />
              </Paragraph>
            </Link>
          </Notification> }
        </When>

        { /* Preview Late Fee children if available */ }
        <When condition={ invoiceData.hasLateFees && invoiceData.hasLateFees.length > 0 }>
          { () => (
            <Card className={ `mt-4` }>
              <Notification
                type={ `warning` }

              >
                { `This invoice has had the following late fees applied:` }
              </Notification>
              <table className={ `min-w-full divide-y divide-gray-300` }>
                <thead>
                  <tr>
                    <th
                      scope={ `col` }
                      className={ `whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0` }
                    >
                      { `Penalty Invoice Number` }
                    </th>
                    <th
                      scope={ `col` }
                      className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                    >
                      { `Issue Date` }
                    </th>
                    <th
                      scope={ `col` }
                      className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                    >
                      { `Invoice Amount` }
                    </th>
                  </tr>
                </thead>
                <tbody className={ `divide-y divide-gray-200 bg-white` }>
                  { invoiceData.hasLateFees.map(lf => {
                    const link = (
                      <Link
                        to={ `/i/${lf.hash}` }>
                        <Tooltip title={ lf.invoiceNumber }>
                          <span>
                            { lf.invoiceNumber }
                            <ArrowTopRightOnSquareIcon className={ `inline-block ml-1 h-4 w-4` } />
                          </span>
                        </Tooltip>
                      </Link>
                    );

                    return (
                      <tr key={ lf.hash }>
                        <td className={
                          classNames(
                            `whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 truncate max-w-[150px]`,
                            `underline`,
                          )
                        }>
                          { link }
                        </td>

                        <td className={ `whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900` }>
                          <DatePopover
                            date={ lf.date }
                            organisationTimezone={ invoiceData.invoice.organisationTimezone }
                            labelFormat={ `dd LLL yy` }
                          />
                        </td>
                        <td className={ `whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900` }>
                          { lf.amount }
                        </td>
                      </tr>
                    );
                  }) }
                </tbody>
              </table>
            </Card>
          )
          }
        </When>

        <Card className={ `mt-6` }>
          <MultiButtonCardHeader
            title={ title }
            buttons={ buttons }
            buttonsAsDropdownText={ `Download` }
            secondaryButtons={ secondaryButtons }
          />
          <InvoiceDocumentCommon
            invoice={ invoiceData.invoice }
            senderDetail={ invoiceData.senderDetail }
            mode={ `portal` }
          />
          <When condition={ invoiceData.footer }>
            <CardContent>
              <Paragraph
                variant={ `secondary` }
                className={ `whitespace-pre-wrap` }>
                { invoiceData.footer }
              </Paragraph>
            </CardContent>
          </When>
        </Card>
        <When condition={ paymentModalOpen }>
          { () => <StripeCheckout
            mode={ `invoice` }
            code={ invoiceData.invoice.hash }
            connectedAccountId={ invoiceData.stripeConnectAccount }
            onClose={ () => setPaymentModalOpen(false) }
          /> }
        </When>
      </div>
    </LoadingOverlay>
  );
}
