import { createApi } from '@reduxjs/toolkit/query/react';

import { env } from '../../common/lib/env';
import { axiosBaseQuery } from '../../common/lib/axiosBaseQuery';

import { PortalCacheTags } from './cacheTags';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: axiosBaseQuery(env.basePortalApiUrl),
  endpoints: () => ({}),
  reducerPath: `portalApi`,
  tagTypes: Object.values(PortalCacheTags),
});

