import { forwardRef } from 'react';
import { statusUIMapXero } from 'shared';

import { Badge } from '../../Atoms/Badge';

interface IXeroStatusBadgeProps {
  status: keyof typeof statusUIMapXero;
  color?: string;
  mode: `app` | `portal`;
}

export const XeroStatusBadge = forwardRef<unknown, IXeroStatusBadgeProps>(function XeroStatusBadge({ status, mode, ...props }, ref) {
  const stats = statusUIMapXero[status];

  if (!stats) {
    return <Badge
      message={ `Unknown Status` }
      { ...props }
      ref={ ref }
    />;
  }

  return <Badge
    message={ mode === `portal` ? stats.portal : stats.name }
    color={ stats.color }
    { ...props }
    ref={ ref }
  />;
});

XeroStatusBadge.displayName = `Invoice Status Badge`;
