import React from 'react';
import { When } from 'react-if';

interface IFormDescriptionProps {
  children: React.ReactNode
}

export function FormDescription({ children }: IFormDescriptionProps) {
  return (
    <When condition={ !!children }>
      <p className={ `mt-2 text-sm text-gray-500` }
        id={ `email-description` }>
        { children }
      </p>
    </When>
  );
}
