import { Notification } from "../../common/Atoms/Notification";

import { NotFound } from "./NotFound";

const allowedMessages = [
  `An error occurred`,
  `is no longer using Paidnice. Please contact them directly.`,
];

export function ErrorMessage({ error }) {
  let errorMessage = error.message || error.data?.message || `An error occurred`;

  // Dont surface unknown errors
  if (!allowedMessages.some(m => errorMessage.includes(m))) {
    errorMessage = `An unknown error occurred`;
  }

  if (error.originalStatus === 404) {
    return <NotFound />;
  }

  return (
    <div className={ `h-screen flex flex-col justify-center items-center` }>
      <Notification
        type={ `error` }
      >
        { errorMessage }
      </Notification>
    </div>
  );
}
