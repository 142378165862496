import React, { Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { classNames }  from '../lib/classNames';

import { Heading } from './Typography/Heading';

const widths = {
  'md': `sm:max-w-2xl`,
  'lg': `sm:max-w-4xl`,
  'xl': `sm:max-w-6xl`,
  '2xl': `sm:max-w-8xl`,
  'stripe': `sm:max-w-[1100px] `,
};

interface IModalProps {
  title: string | React.ReactNode
  children: React.ReactNode
  open: boolean
  setOpen: (open: boolean) => void
  icon?: React.ReactNode
  width?: `md` | `lg` | `xl` | `stripe` | `2xl`
  minHeight?: `400`
}

export function Modal({
  title,
  children,
  open,
  setOpen,
  icon,
  width = `md`,
  minHeight,
}: IModalProps) {
  return (
    <Transition show={ open }
      as={ Fragment }>
      <Dialog as={ `div` }
        className={ `relative z-10` }
        onClose={ setOpen }>
        <TransitionChild
          enter={ `ease-out duration-300` }
          enterFrom={ `opacity-0` }
          enterTo={ `opacity-100` }
          leave={ `ease-in duration-200` }
          leaveFrom={ `opacity-100` }
          leaveTo={ `opacity-0` }
        >
          <div className={ `fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity` } />
        </TransitionChild>

        <div className={ `fixed inset-0 z-10 overflow-y-auto` }>
          <div className={ `flex min-h-full justify-center p-4 text-center items-center sm:p-0` }>
            <TransitionChild
              as={ Fragment }
              enter={ `ease-out duration-300` }
              enterFrom={ `opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95` }
              enterTo={ `opacity-100 translate-y-0 sm:scale-100` }
              leave={ `ease-in duration-200` }
              leaveFrom={ `opacity-100 translate-y-0 sm:scale-100` }
              leaveTo={ `opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95` }
            >
              <DialogPanel
                className={
                  classNames(
                    `relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6`,
                    widths[width],
                    minHeight === `400` && `min-h-[400px]`,
                  )
                }
              >
                <div className={ `absolute top-0 right-0 hidden pt-4 pr-4 sm:block` }>
                  <button
                    type={ `button` }
                    className={ `rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lateGreen-500 focus:ring-offset-2` }
                    onClick={ () => setOpen(false) }
                  >
                    <span className={ `sr-only` }>{ `Close` }</span>
                    <XMarkIcon className={ `h-6 w-6` }
                      aria-hidden={ `true` } />
                  </button>
                </div>
                <div className={ `` }>
                  <div className={ `flex items-center` }>
                    { icon }
                    <div className={ `mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left` }>
                      <DialogTitle
                        as={ Heading }
                      >
                        { title }
                      </DialogTitle>
                    </div>
                  </div>
                  <div className={ `mt-2` }>
                    { children }
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
