import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license';
import { PostHogProvider } from 'posthog-js/react';
import 'react-toastify/dist/ReactToastify.min.css';

import { env } from '../common/lib/env';
import { posthogOptions } from '../common/lib/posthog';

import { App } from './components/App';
import { store } from './store';

import '../common/css/index.css';

// import './services/logrocket';

import '../common/lib/sentry';
// Allowed to be exposed
// https://mui.com/x/introduction/licensing/#license-key-security
LicenseInfo.setLicenseKey(env.muiLicenceKey);

ReactDOM.createRoot(document.getElementById(`root`) as HTMLElement).render(
  <Provider store={ store }>
    <PostHogProvider
      apiKey={ env.posthogApiKey }
      options={ posthogOptions }
    >
      <App />
    </PostHogProvider>
  </Provider>,
);
