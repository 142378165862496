import React, { forwardRef } from 'react';

import { classNames } from '../../lib/classNames';
import { HelpLinkIcon } from '../../../main/components/Molecules/HelpLinkIcon';

interface IHeadingProps {
  as?: React.ElementType
  children: React.ReactNode
  className?: string
  size?: `sm` | `md` | `lg` | `xl` | `2xl` | `3xl` | `4xl` | `5xl` | `6xl`;
  helpLink?: string;
  variant?: `default` | `secondary`
}

export const Heading = forwardRef<HTMLDivElement, IHeadingProps>(({
  helpLink,
  className,
  children,
  size = `md`,
  as: Component= `h3`,
  variant = `default`,
  ...props
}, ref) => {
  const body = (
    <Component
      className={
        classNames(
          `leading-6`,
          size === `6xl` && `text-6xl`,
          size === `5xl` && `text-5xl`,
          size === `4xl` && `text-4xl`,
          size === `3xl` && `text-3xl`,
          size === `2xl` && `text-2xl`,
          size === `xl` && `text-xl`,
          size === `lg` && `text-lg`,
          size === `md` && `text-base`,
          size === `sm` && `text-sm`,
          variant === `default` && `text-gray-900 font-semibold`,
          variant === `secondary` && `text-gray-800 font-medium`,
          className,
        ) }
      { ...props }
    >
      { children }
    </Component>
  );

  if (!helpLink) {
    return body;
  }

  return (
    <div
      ref={ ref }
      className={ `flex flex-row space-x-1 flex-end` }
    >
      { body }
      <HelpLinkIcon href={ helpLink } />
    </div>
  );
});

Heading.displayName = `Heading`;

