import React from 'react';
import { Tooltip } from '@mui/material';

import { classNames } from '../../lib/classNames';

interface ParagraphProps {
  as?: React.ElementType;
  children: React.ReactNode;
  size?: `xs` | `sm` | `md`;
  variant?: `primary` | `secondary` | `help` | `error` | `subHeading` | `warning` | `link` | `note`;
  className?: string;
  /** Also show content in a tooltip */
  tooltipped?: boolean;
}

export function Paragraph({ as: Component = `p`, children, size = `sm`, variant = `primary`, className, tooltipped, ...props }: ParagraphProps) {
  const content = (
    <Component
      { ...props }
      className={ classNames(
        variant === `primary` && `text-gray-700 font-medium`,
        variant === `subHeading` && `text-gray-700 font-normal`,
        variant === `secondary` && `text-gray-500 font-normal`,
        variant === `help` && `text-gray-400 italic`,
        variant === `error` && `text-red-600 font-medium`,
        variant === `warning` && `text-orange-600 font-normal`,
        variant === `link` && `text-lateGreen-400 font-medium underline cursor-pointer`,
        variant === `note` && `text-gray-600 font-light leading-5`,

        size === `xs` && `text-xs`,
        size === `sm` && `text-sm`,
        size === `md` && `text-base`,

        className,
      ) }
    >
      { children }
    </Component>
  );

  if (!tooltipped) return content;

  return (
    <Tooltip title={ children }>
      <span>
        { content }
      </span>
    </Tooltip>
  );
}
