import { HelpIcon } from "./HelpIcon";

interface HelpLinkIconProps {
  href: string;
  className?: string;
}

export function HelpLinkIcon({
  href,
  className,
}: HelpLinkIconProps) {
  return (
    <a
      href={ href }
      target={ `__blank` }
      rel={ `noreferrer noopener` }
      onClick={ e => e.stopPropagation() }
    >
      <HelpIcon
        tooltip={ `View help article` }
        className={ className }
      />
    </a>
  );
}
