import { outstandingColor, paidLateColor } from "../../../main/components/Dashboard/Widgets/InvoiceHistory";

export const InvoiceBreakdown = ({ invoicesLastXDays }) => {
  return (
    <div className={ `mb-5` }>
      { /* Title and Legend */ }
      <div className={ `flex justify-between items-center` }>
        <h4 className={ `text-lg font-bold my-5` }>
          { `Invoice Breakdown` }
        </h4>
        <div className={ `text-sm text-right space-x-4` }>
          <span className={ `flex items-center space-x-2 justify-end` }>
            <span
              className={ `w-3.5 h-3.5 rounded bg-red-500` }
              style={ { backgroundColor: paidLateColor } }
            ></span>
            <span>
              { `Overdue ` }<span className={ `text-gray-500` }>{ `(` }{ invoicesLastXDays.overdue }{ `)` }</span>
            </span>
          </span>

          <span className={ `flex items-center space-x-2 justify-end` }>
            <span
              className={ `w-3.5 h-3.5 rounded bg-yellow-500` }
              style={ { backgroundColor: outstandingColor } }
            ></span>
            <span>
              { `Outstanding ` }<span className={ `text-gray-500` }>{ `(` }{ invoicesLastXDays.outstanding }{ `)` }</span>
            </span>
          </span>
        </div>
      </div>

      { /* Progress Bar */ }
      <div className={ `flex w-full h-5 rounded overflow-hidden` }>
        <div
          className={ `h-full` }
          title={ `Overdue: ${invoicesLastXDays.overdue}` }
          style={ {
            width: `${invoicesLastXDays.overduePercent}%`,
            backgroundColor: paidLateColor,
          } }
        ></div>
        <div
          className={ `h-full` }
          title={ `Outstanding: ${invoicesLastXDays.outstanding}` }
          style={ {
            width: `${invoicesLastXDays.outstandingPercent}%`,
            backgroundColor: outstandingColor,
          } }
        ></div>
      </div>
    </div>
  );
};
