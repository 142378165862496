import React from 'react';
import { When } from 'react-if';
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';

import { classNames }  from '../lib/classNames';

const types = {
  success: {
    icon: CheckCircleIcon,
    color: {
      icon: `text-lateGreen-400`,
      bg: `bg-lateGreen-50`,
      heading: `text-lateGreen-800`,
      content: `text-lateGreen-700`,
      border: `border border-lateGreen-200`,
    },
  },
  successGreen: {
    icon: CheckCircleIcon,
    color: {
      icon: `text-green-400`,
      bg: `bg-green-50`,
      heading: `text-green-800`,
      content: `text-green-700`,
      border: `border border-green-200`,
    },
  },
  warning: {
    icon: ExclamationTriangleIcon,
    color: {
      icon: `text-yellow-400`,
      bg: `bg-yellow-50`,
      heading: `text-yellow-800`,
      content: `text-yellow-700`,
      border: `border border-yellow-200`,
    },
  },
  error: {
    icon: XCircleIcon,
    color: {
      icon: `text-red-400`,
      bg: `bg-red-50`,
      heading: `text-red-800`,
      content: `text-red-700`,
      border: `border border-red-200`,
    },
  },
  info: {
    icon: InformationCircleIcon,
    color: {
      icon: `text-blue-400`,
      bg: `bg-blue-50`,
      heading: `text-blue-800`,
      content: `text-blue-700`,
      border: `border border-blue-200`,
    },
  },
  pro: {
    icon: null,
    color: {
      icon: `text-purple-400`,
      bg: `bg-purple-50`,
      heading: `text-purple-800`,
      content: `text-purple-700`,
      border: `border border-purple-200`,
    },
  },
};

interface INotificationProps {
  type?: `success` | `warning` | `error` | `info` | `pro` | `successGreen`
  title?: string
  buttons?: React.ReactNode
  children?: React.ReactNode
  icon?: typeof ExclamationTriangleIcon; // Use any heroicons for the type
  className?: string
  noIcon?: boolean
  border?: boolean
}

export function Notification({
  type = `info`,
  title,
  buttons,
  children,
  icon,
  className,
  noIcon,
  border,
}: INotificationProps) {
  const {
    color,
    icon: Icon,
  } = types[type];

  const IconOverride = icon || Icon;

  return (
    <div className={
      classNames(
        `rounded-md ${color.bg} p-4 flex justify-between`,
        className,
        border && color.border,
      )
    }>
      <div className={ `flex w-full items-center` }>
        <div className={ `flex-shrink-0` }>
          <When condition={ !!IconOverride && !noIcon }>
            {
              () => <IconOverride className={ `h-5 w-5 ${color.icon}` }
                aria-hidden={ `true` } />
            }
          </When>
        </div>
        <div className={ `ml-3 w-full` }>
          <h3 className={ `text-sm font-medium ${color.heading}` }>{ title }</h3>
          <div className={ `mt-2 text-sm ${color.content}` }>
            <div>
              { children }
            </div>
          </div>

        </div>
      </div>
      <When condition={ !!buttons }>
        <div className={ `mt-4 w-1/4` }>
          <div className={ `flex justify-end` }>
            { buttons }
          </div>
        </div>
      </When>
    </div>
  );
}
