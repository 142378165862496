import React from 'react';
import { Description, Field, Label, Switch } from '@headlessui/react';
import { Else, Then, If, When } from 'react-if';
import { IInvalidField } from 'shared';

import { classNames }  from '../lib/classNames';
import { HelpLinkIcon } from '../../main/components/Molecules/HelpLinkIcon';
import { HelpIcon } from '../../main/components/Molecules/HelpIcon';

import { SmallSpinner } from './SmallSpinner';
import { FormLabel } from './FormLabel';
import { FormDescription } from './FormDescription';
import { Badge } from './Badge';

interface ToggleProps {
  label?: string | React.ReactNode
  checked: boolean
  onChange: (value: { value: boolean, name: string }) => void
  description?: string
  secondaryDescription?: string
  name?: string
  disabled?: boolean
  loading?: boolean
  centerText?: boolean
  paywalled?: boolean
  invalid?: boolean | IInvalidField // TODO
  className?: string;
  helpLink?: string;
  /** Text to go in a help icon tool tip */
  helpIcon?: string;
  helpText?: string;
  ping?: boolean;
  checkedClassname?: string;
  uncheckedClassname?: string;
}

export function Toggle({
  label,
  checked,
  onChange,
  description,
  secondaryDescription,
  name,
  disabled,
  loading,
  centerText,
  paywalled,
  className,
  helpLink,
  helpIcon,
  helpText,
  ping,
  checkedClassname,
  uncheckedClassname,
}: ToggleProps) {

  function handleChange(value) {
    return onChange({
      value,
      name,
    });
  }

  checkedClassname = checkedClassname || `bg-lateGreen-600`;
  uncheckedClassname = uncheckedClassname || `bg-gray-200`;

  return (
    <Field as={ `div` }
      className={
        classNames(
          `flex items-center justify-between`,
          disabled && `opacity-50`,
          className,
        )
      }>
      <span className={
        classNames(
          `flex flex-col`,
          centerText && `justify-center`,
          paywalled && `opacity-50`,
        )
      }>
        <span className={ `flex flex-row` }>
          <Label as={ FormLabel }
            className={ `flex flex-row` }>
            { label }
            <When condition={ helpLink }>
              <span>
                <HelpLinkIcon
                  className={ `ml-2` }
                  href={ helpLink }
                />
              </span>
            </When>

            <When condition={ helpIcon }>
              <span>
                <HelpIcon
                  className={ `ml-2` }
                  tooltip={ helpIcon }
                />
              </span>
            </When>
            {
              paywalled &&
              <Badge color={ `gray` }
                className={ `ml-2` }
                message={ `Upgrade Plan` }/>
            }
          </Label>
          <When condition={ ping }>
            <span className={ `relative flex h-3 w-3 ml-2` }>
              <span className={ `animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75` }></span>
              <span className={ `relative inline-flex rounded-full h-3 w-3 bg-lateGreen-500` }></span>
            </span>
          </When>
        </span>
        <Description as={ FormDescription }>
          { description }
        </Description>
        <When condition={ secondaryDescription }>
          <Description as={ FormDescription }>
            { secondaryDescription }
          </Description>
        </When>
        <When condition={ helpText }>
          <Description className={ `text-gray-400 italic text-sm` }>
            { helpText }
          </Description>
        </When>
      </span>
      <If condition={ loading }>
        <Then>
          <SmallSpinner colored />
        </Then>
        <Else>
          <Switch
            checked={ checked }
            disabled={ disabled || paywalled }
            onChange={ handleChange }
            className={ classNames(
              checked ? checkedClassname : uncheckedClassname,
              `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lateGreen-500 focus:ring-offset-2`,
            ) }
          >
            <span
              aria-hidden={ `true` }
              className={ classNames(
                checked ? `translate-x-5` : `translate-x-0`,
                `pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`,
              ) }
            />
          </Switch>

        </Else>
      </If>
    </Field>
  );
}
