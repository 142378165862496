import { createSlice } from '@reduxjs/toolkit';

export interface IGenericState {
  /** Has the app made identify etc calls */
  hasBootedTracking: boolean;
}

const initialState: IGenericState = {
  hasBootedTracking: false,
};

const genericStateSlice = createSlice({
  name: `genericState`,
  initialState,
  reducers: {
    setState(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setState } = genericStateSlice.actions;

export const genericStateReducer = genericStateSlice.reducer;
