import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";

import { classNames } from "../../../common/lib/classNames";

interface HelpLinkProps {
  tooltip: string;
  className?: string;
}

export function HelpIcon({
  tooltip,
  className,
}: HelpLinkProps) {
  return (

    <Tooltip
      title={ tooltip }
      placement={ `top` }
    >
      <QuestionMarkCircleIcon
        className={
          classNames(
            `w-5 h-5 text-gray-400 hover:text-gray-500`,
            className,
          )
        }
      />
    </Tooltip>
  );
}
