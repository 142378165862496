// Wraps the default react router link, to keep the organisation in the URL
import { Link, LinkProps, useParams } from 'react-router-dom';

interface IWrappedLinkProps extends LinkProps {
  children: React.ReactNode | string;
  to: string;
  disabled?: boolean;
  className?: string;
}

export function WrappedLink({ children, to, disabled, ...props }: IWrappedLinkProps) {
  const { organisationId } = useParams();
  let link = organisationId ? `/${organisationId}${to}` : `${to}`;

  if (disabled) {
    link = undefined;
  }

  return (
    <Link { ...props }
      to={ link }>
      { children }
    </Link>
  );
}
