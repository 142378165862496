import { GroupOptionsOnboarding, IInvoiceHistoryMetricsResponse, IInvoiceListResponse, IOrganisationEmailMetrics, IOrganisationMarketingData, IOrganisationMetrics, IOrganisationMetricsResponse, IOrganisationResponse, IOrganisationResponseItem, ISlowestPayers, PolicyType } from 'shared';

import { orgHeader } from '../../../../common/lib/axiosBaseQuery';
import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const organisationApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchOrganisations: builder.query<IOrganisationResponseItem[], string>({
      query: () => ({
        url: `organisation`,
        method: `GET`,
      }),
      transformResponse: (rawResult: IOrganisationResponse) => {
        return rawResult?.organisations;
      },
      providesTags: result => result ?
        [
          ...result.map(({ id }) => ({ type: CacheTags.Organisations, id })),
          { type: CacheTags.Organisations, id: `LIST` },
        ]
        : [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    fetchOrganisationMetrics: builder.query<IOrganisationMetrics, { organisationId: string; groupId?: number; }>({
      query: ({ organisationId, groupId }) => ({
        url: `metrics`,
        method: `GET`,
        params: {
          previousDays: 90,
          groupId: groupId,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      transformResponse: (rawResult: IOrganisationMetricsResponse) => {
        return rawResult?.metrics;
      },
    }),
    fetchInvoiceHistoryMetrics: builder.query<IInvoiceHistoryMetricsResponse, { orgId: string, period?: number }>({
      query: ({ orgId, period }) => ({
        url: `metrics/history`,
        method: `GET`,
        params: {
          previousMonths: period,
        },
        headers: {
          [orgHeader]: orgId,
        },
      }),
    }),
    fetchEmailMetrics: builder.query<IOrganisationEmailMetrics, { orgId: string }>({
      query: ({ orgId }) => ({
        url: `metrics/email`,
        method: `GET`,
        headers: {
          [orgHeader]: orgId,
        },
      }),
    }),
    fetchSlowPayersMetrics: builder.query<ISlowestPayers, { orgId: string}>({
      query: ({ orgId }) => ({
        url: `metrics/slowpayers`,
        method: `GET`,
        headers: {
          [orgHeader]: orgId,
        },
      }),
      providesTags: [{ type: CacheTags.BadContacts, id: `LIST` }],
    }),
    fetchBadInvoicesMetrics: builder.query<IInvoiceListResponse, { orgId: string}>({
      query: ({ orgId }) => ({
        // We can just get the invoices from invoices endpoint for now, with defined params
        url: `invoice`,
        method: `GET`,
        headers: {
          [orgHeader]: orgId,
        },
        params: {
          limit: 10,
          page: 1,
          sortColumn: `due_date_string`,
          sortType: `asc`,
          dueDate: `overdue`,
          xeroStatus: `AUTHORISED`,
        },
      }),
      providesTags: [{ type: CacheTags.BadInvoices, id: `LIST` }],
    }),
    inviteSupport: builder.mutation<void, string>({
      query: organisationId => ({
        url: `organisation/${organisationId}/invite-support`,
        method: `GET`,
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    removeSupport: builder.mutation<void, string>({
      query: organisationId => ({
        url: `organisation/${organisationId}/remove-support`,
        method: `GET`,
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    enablePinchPayments: builder.mutation<void, string>({
      query: organisationId => ({
        url: `organisation/${organisationId}/payments/pinch`,
        method: `PUT`,
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    disablePinchPayments: builder.mutation<void, string>({
      query: organisationId => ({
        url: `organisation/${organisationId}/payments/pinch`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    saveWelcomeQuestions: builder.mutation<void, IOrganisationMarketingData & { organisationId: string }>({
      query: data => ({
        url: `organisation/${data.organisationId}/marketing-data`,
        method: `PATCH`,
        data,
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    saveOnboardingGroupPolicies: builder.mutation<void, { organisationId: string, groupOption: GroupOptionsOnboarding, selectedPolicies: PolicyType[] }>({
      query: data => ({
        url: `organisation/${data.organisationId}/onboarding/group-policies`,
        method: `POST`,
        data,
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
        { type: CacheTags.Policies, id: `LIST` },
        { type: CacheTags.Groups, id: `LIST` },
      ],
    }),
    saveOnboardingComplete: builder.mutation<void, string>({
      query: organisationId => ({
        url: `organisation/${organisationId}/onboarding/complete`,
        method: `POST`,
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    saveOnboardingSkipped: builder.mutation<void, string>({
      query: organisationId => ({
        url: `organisation/${organisationId}/onboarding/complete`,
        method: `POST`,
        data: {
          skipped: true,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
  }),
});

export const {
  useFetchOrganisationsQuery,
  useFetchOrganisationMetricsQuery,
  useInviteSupportMutation,
  useRemoveSupportMutation,
  useEnablePinchPaymentsMutation,
  useDisablePinchPaymentsMutation,
  useSaveWelcomeQuestionsMutation,
  useSaveOnboardingGroupPoliciesMutation,
  useSaveOnboardingCompleteMutation,
  useSaveOnboardingSkippedMutation,
  useFetchInvoiceHistoryMetricsQuery,
  useFetchEmailMetricsQuery,
  useFetchSlowPayersMetricsQuery,
  useFetchBadInvoicesMetricsQuery,
} = organisationApi;
