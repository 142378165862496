import React from 'react';

import { classNames }  from '../lib/classNames';

interface ICardContentProps {
  children: React.ReactNode
  className?: string
}

/** Keeps consistent padding with headers */
export function CardContent({ children, className }: ICardContentProps) {
  return (
    <div className={
      classNames(
        `px-4 sm:px-5`,
        className,
      )
    }>
      { children }
    </div>
  );
}
