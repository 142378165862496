import { createApi } from '@reduxjs/toolkit/query/react';

import { CacheTags } from '../types/cacheTags';
import { axiosBaseQuery } from '../../../common/lib/axiosBaseQuery';
import { env } from '../../../common/lib/env';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: axiosBaseQuery(env.baseApiUrl),
  endpoints: () => ({}),
  reducerPath: `api`,
  tagTypes: Object.values(CacheTags),
});
