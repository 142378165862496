import { IPortalFileUploadResponse, IPortalQuoteResponse } from "shared";

import { emptySplitApi } from "../baseApi";
import { PortalCacheTags } from "../cacheTags";

export const quoteApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchQuote: builder.query<IPortalQuoteResponse, { code: string, sessionId: string }>({
      query: ({ code, sessionId }) => ({
        url: `quote/code/${code}`,
        method: `GET`,
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
      }),
      providesTags: () => [PortalCacheTags.Quote],
    }),
    acceptQuote: builder.mutation<void, { code: string, sessionId: string, poNumber?: string, poUploadUrl?: string, poUploadName?: string }>({
      query: ({ code, sessionId, poNumber, poUploadUrl, poUploadName }) => ({
        url: `quote/code/${code}/accept`,
        method: `POST`,
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
        data: {
          poNumber,
          poUploadUrl,
          poUploadName,
        },
      }),
      invalidatesTags: [PortalCacheTags.Quote],
    }),
    declineQuote: builder.mutation<void, { code: string, sessionId: string }>({
      query: ({ code, sessionId }) => ({
        url: `quote/code/${code}/decline`,
        method: `POST`,
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
      }),
      invalidatesTags: [PortalCacheTags.Quote],
    }),
    uploadPOFile: builder.mutation<IPortalFileUploadResponse, { code: string, sessionId: string, formData: FormData }>({
      query: ({ code, sessionId, formData }) => ({
        url: `quote/code/${code}/po-upload`,
        method: `POST`,
        headers: {
          'Content-Type': `multipart/form-data`,
          [`X-Portal-Session`]: sessionId,
        },
        data: formData,
      }),
      invalidatesTags: [PortalCacheTags.Quote],
    }),
  }),
});

export const {
  useFetchQuoteQuery,
  useAcceptQuoteMutation,
  useDeclineQuoteMutation,
  useUploadPOFileMutation,
} = quoteApi;

