import { ISenderDetail } from "shared";
import { When } from "react-if";

import { Heading } from "../../Atoms/Typography/Heading";
import { Paragraph } from "../../Atoms/Typography/Paragraph";

interface InvoiceSenderDetailProps {
  senderDetail: ISenderDetail;
}

export function InvoiceSenderDetail({ senderDetail }: InvoiceSenderDetailProps) {
  return (
    <div>
      <dt className={ `font-semibold text-gray-900` }>{ `From` }</dt>
      <div className={ `space-y-3` }>
        <dd className={ `mt-2 text-gray-500` }>
          <Heading
            variant={ `secondary` }
          >
            { senderDetail.customerFacingCompanyName }
          </Heading>
        </dd>

        <When condition={ senderDetail.companyNumber }>
          <div className={ `flex space-x-3` }>
            <Paragraph
            >
              { senderDetail.companyNumberType }
            </Paragraph>
            <Paragraph
              variant={ `secondary` }
            >
              { senderDetail.companyNumber }
            </Paragraph>
          </div>
        </When>

        <When condition={ senderDetail.taxNumber }>
          <Paragraph
            variant={ `secondary` }
          >
            { senderDetail.taxNumber }
          </Paragraph>
        </When>
      </div>
    </div>
  );
}
