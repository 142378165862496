import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { env } from '../common/lib/env';
import { createMiddleware } from '../common/middleware';
import { genericStateReducer } from '../common/slices/genericState';

import { emptySplitApi as extendedApi } from './services/baseApi';

const rootReducer = combineReducers({
  [extendedApi.reducerPath]: extendedApi.reducer,
  genericState: genericStateReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => createMiddleware(getDefaultMiddleware, extendedApi.middleware),
  devTools: !env.isProd,
});
