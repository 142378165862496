import { PostHogConfig } from "posthog-js";

import { env } from "./env";

export const posthogEnabled = env.isProd || env.posthogEnabledOverride;

export const posthogOptions: Partial<PostHogConfig> = {
  api_host: env.posthogApiHost,
  ui_host: env.posthogUiHost,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
  disable_session_recording: !posthogEnabled, // only record in prod normally
};

