import React from 'react';
import { Transition as HeadlessTransition } from '@headlessui/react';

import { classNames }  from '../lib/classNames';

const speeds = {
  fast: `duration-150`,
  normal: `duration-300`,
  slow: `duration-500`,
  verySlow: `duration-1000`,
};

interface TransitionProps {
  children: React.ReactNode;
  show: boolean;
  speed?: keyof typeof speeds;
}

export function Transition({
  children,
  show,
  speed = `normal`,
}: TransitionProps) {
  return (
    <HeadlessTransition
      show={ show }
      enter={
        classNames(
          `transition-opacity`,
          speeds[speed],
        ) }
      enterFrom={ `opacity-0` }
      enterTo={ `opacity-100` }
      leave={
        classNames(
          `transition-opacity`,
          speeds[speed],
        )
      }
      leaveFrom={ `opacity-100` }
      leaveTo={ `opacity-0` }
    >
      { children }
    </HeadlessTransition>
  );
}
