import { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { When } from 'react-if';
import { Tooltip } from '@mui/material';

import { classNames }  from '../lib/classNames';
import { IHeroIcon } from '../../main/types/types';

import { Badge } from './Badge';

export interface ITab {
  name: string;
  href: string;
  icon?: IHeroIcon;
  new?: boolean;
  tooltip?: string;
  onClick?: () => void;
  current?: boolean;
  badge?: {
    text: string;
    color: string;
    tooltip?: string;
  }
  secondaryBadge?: {
    text: string;
    className?: string;
    tooltip?: string;
  }
}

interface ITabsProps {
  tabs: ITab[];
  navigational?: boolean;
  className?: string;
  tabStyle?: `default` | `v2`;
  small?: boolean;
}

export  function Tabs({
  tabs,
  navigational = true,
  className,
  tabStyle = `default`,
  small,
}: ITabsProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentTab = useMemo(() => {
    if (navigational) {
      const tabPaths = tabs.map(tab => tab.href);
      const parts = pathname.split(`/`);
      const lastPart = parts[parts.length - 1];

      if (tabPaths.includes(``) && !tabPaths.includes(lastPart)) {
        // One path is the root, so the last path makes no sense, we must be on the root path
        return tabs.find(tab => tab.href === ``)?.name;
      }

      return tabs.find(tab => tab.href === lastPart)?.name;
    }
    else {
      return tabs.find(tab => tab.current)?.name;
    }
  }, [pathname, tabs, navigational]);

  function handleMobileChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedTab = tabs.find(tab => tab.name === e.target.value);

    if (selectedTab && selectedTab.onClick) {
      selectedTab.onClick();
    }

    navigate(selectedTab?.href);
  }

  return (
    <div className={ classNames(
      tabStyle === `v2` ? `py-4` : `pb-6`,
      className,
    ) }>
      <div className={ `md:hidden` }>
        <label htmlFor={ `tabs` }
          className={ `sr-only` }>
          { `Select a tab` }
        </label>
        { /* Use an "onChange" listener to redirect the user to the selected tab URL. */ }
        <select
          id={ `tabs` }
          name={ `tabs` }
          className={ `block w-full rounded-md border-gray-300` }
          defaultValue={ currentTab }
          onChange={ handleMobileChange }
        >
          { tabs.map(tab => (
            <option key={ tab.name }>{ tab.name }</option>
          )) }
        </select>
      </div>
      <div className={ `hidden md:block` }>
        <div className={ tabStyle === `v2` ? `` : `border-b border-gray-200` }>
          <nav className={
            tabStyle === `v2` ?
              `-mb-px flex justify-center` :
              `-mb-px flex space-x-8`
          }
          aria-label={ `Tabs` }>
            { tabs.map((tab, tabIdx) => {
              const current = tab.name === currentTab || tab.current;

              const el = (
                <Link
                  key={ tab.name }
                  to={ tab.href }
                  onClick={ e => {
                    if (tab.onClick) {
                      // When onClick is defined, we override the default behavior
                      e.preventDefault();
                      tab.onClick();
                    }
                  } }
                  className={
                    tabStyle === `v2` ?
                      classNames(
                        `relative group inline-flex items-center py-4 px-12 lg:px-28 w-full justify-center text-sm font-medium bg-white`,
                        `border`,
                        tabIdx === 0 ? `border-r-0 rounded-l-md` : `border-r`,
                        tabIdx !== tabs.length - 1 ? `border-r-0 rounded-r-0` : `border-r rounded-r-md`,
                        current
                          ? `text-lateGreen-600`
                          : ` text-gray-500 hover:text-gray-700 hover:bg-gray-50`,

                      ) :
                      classNames(
                        current
                          ? `border-lateGreen-500 text-lateGreen-600`
                          : ` text-gray-500 hover:border-gray-300 hover:text-gray-700`,
                        `group inline-flex items-center border-b-2 px-1 text-sm font-medium`,
                        small ? `py-1 font-normal` : `py-4`,
                      ) }
                  aria-current={ current ? `page` : undefined }
                >
                  <When condition={ !!tab.icon }>
                    { () => (
                      <tab.icon
                        className={ classNames(
                          current ? `text-lateGreen-500` : `text-gray-400 group-hover:text-gray-500`,
                          `-ml-0.5 mr-2 h-5 w-5`,
                        ) }
                        aria-hidden={ `true` }
                      />
                    ) }
                  </When>
                  <span className={ `mr-2` }>{ tab.name }</span>
                  <When condition={ tabStyle === `v2` }>
                    { /* The bottom border */ }
                    <span
                      aria-hidden={ `true` }
                      className={ classNames(
                        current ? `bg-lateGreen-400` : `bg-transparent`,
                        `absolute inset-x-0 bottom-0 h-0.5`,
                      ) }
                    />
                  </When>
                  <When condition={ !!tab.badge }>
                    {
                      () => (
                        tabStyle === `v2` ?
                          (
                            <Tooltip
                              disableHoverListener={ !tab.badge.tooltip }
                              title={ tab.badge.tooltip }
                            >
                              <span
                                className={ classNames(
                                  current ? `bg-lateGreen-100 text-lateGreen-600` : `bg-gray-100 text-gray-900`,
                                  `ml-1 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block`,
                                ) }
                              >
                                { tab.badge.text }
                              </span>
                            </Tooltip>

                          ) : (
                            <Badge
                              color={ tab.badge.color }
                              size={ `xs` }
                            >
                              { tab.badge.text }
                            </Badge>
                          )
                      )
                    }
                  </When>
                  <When condition={ !!tab.secondaryBadge }>
                    {
                      () => (
                        tabStyle === `v2` ?
                          (
                            <Tooltip
                              disableHoverListener={ !tab.secondaryBadge.tooltip }
                              title={ tab.secondaryBadge.tooltip }
                            >
                              <span
                                className={ classNames(
                                  tab.secondaryBadge.className,
                                  `ml-1 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block`,
                                ) }
                              >
                                { tab.secondaryBadge.text }
                              </span>
                            </Tooltip>

                          ) : (
                            <Badge
                              size={ `xs` }
                            >
                              { tab.secondaryBadge.text }
                            </Badge>
                          )
                      )
                    }
                  </When>
                  <When condition={ tab.new }>
                    <Badge
                      color={ `purple` }
                    >
                      { `NEW` }
                    </Badge>
                  </When>
                </Link>
              );

              if (tab.tooltip) {
                return (
                  <Tooltip
                    key={ tab.name }
                    title={ tab.tooltip }
                  >
                    { el }
                  </Tooltip>
                );
              }

              return el;
            }) }
          </nav>
        </div>
      </div>
    </div>
  );
}
