import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { When } from "react-if";
import { useMemo, useState } from "react";
import { IPortalQuote, QuoteStatus } from "shared";

import { Card } from "../../../common/Atoms/Card";
import { useSession } from "../../hooks/useSession";
import { useFetchContactQuery } from "../../services/api/contact";
import { LoadingFallback } from "../LoadingFallback";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { DescriptionList } from "../../../common/Atoms/DescriptionList";
import { ITab, Tabs } from "../../../common/Atoms/Tabs";
import { classNames } from "../../../common/lib/classNames";

export function Quotes() {
  const { code } = useParams();
  const session = useSession();
  const { isLoading, data: contactResult } = useFetchContactQuery({ code, sessionId: session?.id }, { skip: !code || !session?.id });
  const [currentTab, setCurrentTab] = useState<QuoteStatus>(QuoteStatus.SENT);

  const quoteList: {
    [key in QuoteStatus]?: IPortalQuote[]
  } = useMemo(() => {
    if (!contactResult || !contactResult.quotes) {
      return {
        [QuoteStatus.SENT]: [],
        [QuoteStatus.ACCEPTED]: [],
        [QuoteStatus.DECLINED]: [],
      };
    }

    const result = {
      [QuoteStatus.SENT]: [],
      [QuoteStatus.ACCEPTED]: [],
      [QuoteStatus.DECLINED]: [],
    };

    contactResult.quotes.forEach(quote => {
      if (result[quote.status]) {
        result[quote.status].push(quote);

        if (quote.status === QuoteStatus.CONVERTED) {
          // also add to accepted
          result[QuoteStatus.ACCEPTED].push(quote);
        }
      }
    });

    return result;
  }, [contactResult]);

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: `Outstanding`,
        onClick: () => setCurrentTab(QuoteStatus.SENT),
        current: currentTab === QuoteStatus.SENT,
        href: `#`,
        badge:  quoteList[QuoteStatus.SENT] && {
          text: `${quoteList[QuoteStatus.SENT]?.length}`,
          color: `gray`,
        },
      },
      {
        name: `Accepted`,
        onClick: () => setCurrentTab(QuoteStatus.ACCEPTED),
        current: currentTab === QuoteStatus.ACCEPTED,
        href: `#`,
        badge:  quoteList[QuoteStatus.ACCEPTED] && {
          text: `${quoteList[QuoteStatus.ACCEPTED]?.length}`,
          color: `gray`,
        },
      },
      {
        name: `Declined`,
        onClick: () => setCurrentTab(QuoteStatus.DECLINED),
        current: currentTab === QuoteStatus.DECLINED,
        href: `#`,
        badge:  quoteList[QuoteStatus.DECLINED] && {
          text: `${quoteList[QuoteStatus.DECLINED]?.length}`,
          color: `gray`,
        },
      },
    ];
  }, [contactResult, currentTab]);

  if (isLoading) {
    return <LoadingFallback />;
  }

  const content = quoteList[currentTab].length > 0 ? (
    <div>
      <DescriptionList
        items={ quoteList[currentTab].map(quote => ({
          title: quote.number,
          subText: quote.referenceText,
          content: (
            <Paragraph
              variant={ `secondary` }
            >
              { quote.amountTotalFormatted }
            </Paragraph>
          ),
          child: (
            <div className={ `flex flex-col items-end` }>
              <Paragraph
                variant={ `link` }

              >
                <Link to={ `/q/${quote.hash}` }>
                  { `View Quote` }
                </Link>
              </Paragraph>

              <When condition={ quote.issueDateString }>
                <Paragraph
                  variant={ `secondary` }
                >
                  { `Issued: ${quote.issueDateString}` }
                </Paragraph>
              </When>
              <When condition={ quote.expiryDateString }>
                <Paragraph
                  variant={ `secondary` }
                >
                  { `Expires: ` }
                  <Paragraph
                    as={ `span` }
                    variant={ `secondary` }
                    className={ classNames(
                      quote.hasExpired && `text-red-500`,
                    ) }
                  >
                    { quote.expiryDateString }
                  </Paragraph>
                </Paragraph>
              </When>
            </div>
          ),
        })) }
      />
    </div>
  ) : (
    <Paragraph
      variant={ `secondary` }
    >
      { `No current quotes found` }
    </Paragraph>
  );

  return (
    <Card>
      <Tabs
        tabs={ tabs }
      />
      { content }
    </Card>
  );
}
