import { forwardRef } from 'react';
import { ActionStatus, ActionType, PolicyType } from 'shared';

import { classNames }  from '../lib/classNames';

const classes = {
  gray: `bg-gray-50 text-gray-600 ring-gray-500/10`,
  red: ` bg-red-50 text-red-700 ring-red-600/20`,
  yellow: `bg-yellow-50 text-yellow-700 ring-yellow-600/20`,
  green: `bg-green-50 text-green-700 ring-green-600/20`,
  blue: `bg-blue-50 text-blue-700 ring-blue-600/20`,
  orange: `bg-orange-50 text-orange-700 ring-orange-600/20`,
  purple: `bg-purple-50 text-purple-700 ring-purple-600/20`,
  default: `bg-lateGreen-50 text-lateGreen-600 ring-lateGreen-500/10`,
  indigo: `bg-indigo-50 text-indigo-700 ring-indigo-600/20`,

  // Action & Policy Colors
  [PolicyType.LATE_FEE]: `bg-orange-50 text-orange-700 ring-orange-600/20`,
  [ActionType.LATE_FEE]: `bg-orange-50 text-orange-700 ring-orange-600/20`,
  [ActionType.SEND_STATEMENT_INTEREST_EMAIL]: `bg-orange-50 text-orange-700 ring-orange-600/20`,
  [ActionType.LATE_FEE_EMAIL]: `bg-orange-50 text-orange-700 ring-orange-600/20`,

  [PolicyType.DISCOUNT]: `bg-purple-50 text-purple-700 ring-purple-600/20`,
  [ActionType.DISCOUNT_APPLY]: `bg-purple-50 text-purple-700 ring-purple-600/20`,
  [ActionType.DISCOUNT_REMOVE]: `bg-purple-50 text-purple-700 ring-purple-600/20`,
  [ActionType.DISCOUNT_APPLIED_EMAIL]: `bg-purple-50 text-purple-700 ring-purple-600/20`,
  [ActionType.DISCOUNT_REMOVED_EMAIL]: `bg-purple-50 text-purple-700 ring-purple-600/20`,

  [ActionType.REMINDER]: `bg-sky-50 text-sky-700 ring-sky-600/20`,

  [PolicyType.SMS]: `bg-teal-50 text-teal-700 ring-teal-600/20`,
  [ActionType.SEND_SMS]: `bg-teal-50 text-teal-700 ring-teal-600/20`,
  // Action Status Colors
  [ActionStatus.COMPLETE]: `bg-green-50 text-green-700 ring-green-600/20`,
  [ActionStatus.PENDING]: `bg-blue-50 text-blue-700 ring-blue-600/20`,
  [ActionStatus.APPROVAL_PENDING]: `bg-orange-50 text-orange-700 ring-orange-600/20`,
  [ActionStatus.FAILED]: `bg-red-50 text-red-700 ring-red-600/20`,
  [ActionStatus.SUSPENDED]: `bg-yellow-50 text-yellow-700 ring-yellow-600/20`,
  [ActionStatus.REVERTED]: `bg-gray-50 text-gray-600 ring-gray-500/10`,
};

const sizes = {
  xs: `text-xs px-1.5 py-0.5`,
  sm: `text-sm px-2 py-1`,
  md: `text-md px-2 py-1`,
  lg: `text-lg`, // TODO
  xl: `text-xl px-2 py-1`,
};

interface IBadgeProps {
  className?: string;
  color?: keyof typeof classes;
  message?: string;
  size?: keyof typeof sizes;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Badge = forwardRef<IBadgeProps, any>(function BadgeComp({ className, color = `gray`, message, size = `xs`, ...props }, ref) {
  return (
    <span
      { ...props }
      ref={ ref }
      className={
        classNames(
          `rounded-md whitespace-nowrap mt-0.5 font-medium ring-1 ring-inset`,
          classes[color],
          sizes[size],
          props.children && `flex flex-row items-center`,
          className,
        )
      }
    >
      { message }
      {
        props.children
      }
    </span>
  );
});
