import { When } from "react-if";

interface InvoiceLineItemProps {
  description?: string;
  quantity?: number | string;
  unitAmount?: number | string;
  taxAmount?: number | string;
  totalAmount?: number | string;
  accountCode?: string;
  includeAccountCode?: boolean;
}

export function InvoiceLineItem({
  description,
  quantity,
  unitAmount,
  accountCode,
  taxAmount,
  totalAmount,
  includeAccountCode,
}: InvoiceLineItemProps) {
  return (
    <tr
      className={ `border-b border-gray-100` }>
      <td className={ `max-w-0 px-0 py-5 align-top` }>
        { /* <div className="truncate font-medium text-gray-900">{item.title}</div> */ }
        <div className={ `truncate text-gray-500 whitespace-break-spaces` }>{ description }</div>
      </td>
      <td className={ `hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell` }>
        { quantity }
      </td>
      <When condition={ includeAccountCode }>
        <td className={ `hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell` }>
          { accountCode }
        </td>
      </When>
      <td className={ `hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell` }>
        { unitAmount }
      </td>
      <td className={ `py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700` }
        title= { taxAmount ? `tax: ${taxAmount}` : `not taxed` }>
        { totalAmount }
      </td>
    </tr>
  );
}
